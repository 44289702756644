/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import 'swiper/scss';
@import 'swiper/scss/effect-cards';
@import 'swiper/css/virtual';
@import 'swiper/css/pagination';
@import 'swiper/css/scrollbar';
@import 'swiper/css/effect-flip';


// swiper/css/a11y - styles required for A11y module
// swiper/css/autoplay - styles required for Autoplay module
// swiper/css/controller - styles required for Controller module
// swiper/css/effect-cards - styles required for Cards Effect module
// swiper/css/effect-coverflow - styles required for Coverflow Effect module
// swiper/css/effect-creative - styles required for Creative Effect module
// swiper/css/effect-cube - styles required for Cube Effect module
// swiper/css/effect-fade - styles required for Fade Effect module
// 
// swiper/css/free-mode - styles required for Free Mode module
// swiper/css/grid - styles required for Grid module
// swiper/css/hash-navigation - styles required for Hash Navigation module
// swiper/css/history - styles required for History module
// swiper/css/keyboard - styles required for Keyboard module
// swiper/css/lazy - styles required for Lazy module
// swiper/css/manipulation - styles required for Manipulation module
// swiper/css/mousewheel - styles required for Mousewheel module
// swiper/css/navigation - styles required for Navigation module
// swiper/css/pagination - styles required for Pagination module
// swiper/css/parallax - styles required for Parallax module

// swiper/css/thumbs - styles required for Thumbs module
// swiper/css/virtual - styles required for Virtual module
// swiper/css/zoom - styles required for Zoom module





@font-face {   
    font-family: 'Roboto';    //This is what we are going to call
    src: url('./assets/Fonts/Roboto-Regular.ttf');
  }
  
  @font-face {   
    font-family: 'OpenSans';    //This is what we are going to call
    src: url('./assets/Fonts/OpenSans-SemiBold.ttf');
  }

  *{
    font-family:OpenSans
  }

  .spinner-no-background{
    --background: transparent;
    --box-shadow: none;
    div{
      background: transparent;
      --box-shadow: none;
      --background: transparent;
    }
    .loading-wrapper.sc-ion-loading-md{
      background: transparent;
      box-shadow: none;
      width: 10em;
    }
    .loading-wrapper.sc-ion-loading-ios{
      background: transparent;
      box-shadow: none;
      width: 10em;
    }
}

.alert-no-background{
  --background:transparent;
}

.center-absolute{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

ion-alert{
  --background:transparent;
}

.spinner-with-background{
  div{
    border-radius:0px 50% 50% 50%;
    --border-radius:0px 50% 50% 50%;
  }
  .loading-wrapper.sc-ion-loading-md{
    --border-radius:0px 50% 50% 50%;
    border-radius:0px 50% 50% 50%;
    border: 7px solid var(--ion-color-secondary-shade);
    width: 8em;
  }
  .loading-wrapper.sc-ion-loading-ios{
    --border-radius:0px 50% 50% 50%;
    border-radius:0px 50% 50% 50%;
    border: 7px solid var(--ion-color-secondary-shade);
    width: 9em;
    height: 9em;
  }
}
.qrCode{
    border-radius: 9px;
    background: #feffff;
    padding: 1em;
    img{
      width: 100%;
      height: 100%;
    }
}
.spinner-rounded-background{
  div{
    border-radius:18px 18px 18px 18px;
    --border-radius:18px 18px 18px 18px;
  }
  .loading-wrapper.sc-ion-loading-md{
    --border-radius:18px 18px 18px 18px;
    border-radius:18px 18px 18px 18px;
   
  }
  .loading-wrapper.sc-ion-loading-ios{
    --border-radius:18px 18px 18px 18px;
    border-radius:18px 18px 18px 18px;
  }
}

.nfc-spinner{
  div{
    border-radius:8px;
    --border-radius:8px;
  }
  .loading-wrapper.sc-ion-loading-md{
    --border-radius:8px;
    border-radius:8px;
  }
  .loading-wrapper.sc-ion-loading-ios{
    --border-radius:8px;
    border-radius:8px;
  }
}

.custom-alert-shape{
  .alert-wrapper.sc-ion-alert-md {
    border-radius: 50px;
  //  background: var(--ion-color-secondary);
    .alert-message.sc-ion-alert-md{
      color: var(--ion-color-light);
    }
    .alert-head.sc-ion-alert-md{
      color: var(--ion-color-light);
      .alert-title.sc-ion-alert-md{
        color: var(--ion-color-light);
      }
      .alert-message.sc-ion-alert-md{
        color: var(--ion-color-light);
      }
    }
    .alert-radio-group.sc-ion-alert-md{
      border-top: 0px;
      border-bottom: 0px;
      .alert-radio-button.alert-tappable.alert-radio.ion-focusable.sc-ion-alert-md{
        .alert-button-inner.sc-ion-alert-md{
          .alert-radio-icon.sc-ion-alert-md{
            border-color: #ffffff;
            .alert-radio-inner.sc-ion-alert-md{
              color: var(--ion-color-light);
            }
          }
          .alert-radio-label.sc-ion-alert-md{
            color: var(--ion-color-light);
          }
        }
      }
    }
    .alert-button-group.sc-ion-alert-md{
      .alert-button.ion-focusable.ion-activatable.alert-button-role-cancel.secondary.sc-ion-alert-md{
        .alert-button-inner .sc-ion-alert-md{
          color: var(--ion-color-light);
        }
      }
      .alert-button.ion-focusable.ion-activatable.sc-ion-alert-md{
      
        color: white;
        .alert-button-inner.sc-ion-alert-md{
          color: var(--ion-color-light);
        }
      }
    }
    
  }
  .alert-wrapper.sc-ion-alert-ios {
    .alert-head .sc-ion-alert-ios{
      .alert-title .sc-ion-alert-ios{
      }
      .alert-message .sc-ion-alert-ios{
      }
    }
    .alert-message.sc-ion-alert-ios{
      max-height: fit-content;
    }
    .alert-radio-group .sc-ion-alert-ios{
      .alert-radio-button .alert-tappable .alert-radio .ion-focusable .sc-ion-alert-ios{
        .alert-button-inner .sc-ion-alert-ios{
          .alert-radio-icon .sc-ion-alert-ios{
            .alert-radio-inner .sc-ion-alert-ios{

            }
          }
          .alert-radio-label .sc-ion-alert-ios{

          }
        }
      }
    }
    .alert-button-group .sc-ion-alert-ios{
      .alert-button .ion-focusable .ion-activatable .alert-button-role-cancel .secondary .sc-ion-alert-ios{
        .alert-button-inner .sc-ion-alert-ios{
          
        }
      }
      .alert-button .ion-focusable .ion-activatable .sc-ion-alert-ios{
        .alert-button-inner .sc-ion-alert-ios{

        }
      }
    }
    
  }
}

.fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-6-6 21:38:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    display: block;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    display: block;
  }
}

.fade-out {
	-webkit-animation: fade-out 1s ease-out both;
	        animation: fade-out 1s ease-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-6-6 21:38:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
 @-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}


.swing-in-top-fwd {
  -webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
          animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10%;
  text-align: center;
}

.bottom-center{
  position: absolute;
  left: 50%;
  top: 66%;
  transform: translate(-50%, -50%);
  padding: 10%;
  text-align: center;
}

.background1{
 // aspect-ratio: 450/900;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
 // --background: url(./assets/stacked-waves-haikei.svg);
 --background: var(--ion-color-light);
}

.center-less-padding{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 1%;
  text-align: center;
}

.top-center-no-padding{
  position: relative;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  text-align: center;
}

ion-fab-button{
  --border-radius:18px;
}

ion-modal{
  --border-radius: 18px;
}

.yumdealz-toast::part(container){
    background: var(--ion-color-secondary);
    color: var(--ion-color-light);
    border-radius: 8px;
}

.yumdealz-toast::part(button){
  color: var(--ion-color-light);
}


.centerThis{
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}


.action-button {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  ion-button {
    text-transform: capitalize;
    font-weight: 300;
    --background: var(--ion-color-secondary-shade);
    --border-radius: 50px;
    --box-shadow:none;
  }
  ion-icon{
    margin-right:1px;
  }

  ion-backdrop{
    z-index: 100;
  }
}

  @media (max-width: 350px) {
    p,h1,h2,h3,h4,h5,h6,
    ion-text,ion-note,
    ion-title,ion-label,ion-chip,ion-avatar,
    div,ion-item,ion-slide,ion-button,ion-card-title,
    .price-bubble h2,
    ion-alert, .custom-alert-shape {
      font-size: 0.9em;
    }
    ion-badge{
      font-size: 0.9em;
    }
    ion-tab-bar{
      height: 8%;
    }
    ion-fab,ion-fab-button{
      font-size: 0.9em;
    }
}

.fok{
  width: 90%;
}

  .swiper-pagination-bullet-active{
    background: var(--ion-color-primary);
  }

  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill{
    background: var(--ion-color-primary);
  }
ion-button{
  --border-radius: 8px;
}

body.scanner-active {
  --background: transparent;
  --ion-background-color: transparent;
}

.warn-text{
  color: var(--ion-color-warning);
}
.success-text{
  color: var(--ion-color-success);
}
.danger-text{
  color: var(--ion-color-danger);
}

.warn-background{
  background: var(--ion-color-warning);
}
.success-background{
  background: var(--ion-color-success);
}
.danger-background{
  background: var(--ion-color-danger);
}


.stamp-logo-svg{
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
  border-radius: 50%;
    #outer_border{
        fill: var(--ion-color-dark);
    }
    #background{
        fill: var(--ion-color-light);
    }
    #main_text{
        fill: var(--ion-color-dark);
        .name-letter{
            stroke-width: 0.1;
            stroke: var(--ion-color-dark);
            stroke-dasharray:20;
            stroke-dashoffset:20;
        }
    }
    #subtext{
        fill: var(--ion-color-primary);
        .slogan-letter{
            stroke-width: 0.1;
            stroke: var(--ion-color-primary);
            stroke-dasharray:10;
            stroke-dashoffset:10;
        }
    }
    #image{
        #center_border{
            stroke-width: 1.5;
            stroke: var(--ion-color-dark);
            transform-origin: center;
            transform: rotate(90deg);
            stroke-dasharray: 82;
            stroke-dashoffset: 164;
        }
    
        #green_center{
            transform-origin: center;
            transform: rotate(224deg);
            fill: var(--ion-color-secondary);
            #center_tip{
                fill: var(--ion-color-secondary);
            }
            #green_center_circle{
                fill: var(--ion-color-secondary);
            }
        }
    
        #white_center{
            fill: var(--ion-color-light);
        }
    }
}

.stamp-button {
  display: block;
  position: relative;
  width: 12em;
  height: 12em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  border-radius: 50%;
  border: 8px solid #ececec;
  outline: none;
}

.stamp-loading {
  display: block;
  position: relative;
  width: 12em;
  height: 12em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  border-radius: 50%;
}

.stamp-button.depressed{
  //animation: button-emerge-animation 0.2s ease 0ms 1 forwards; // this fails on ios sometimes
  box-shadow: 10px 10px 15px var(--ion-box-shadow-color), -10px -10px 15px rgba($color: #ffffff, $alpha: 0.5);

}

.hidden{
  visibility: hidden;
}

.stamp-button.pressed{
  animation: button-press-animation 0.2s ease 0ms 1 forwards;
}

.no-shadow{
  box-shadow: none;
}


@keyframes button-emerge-animation{
  from{
    box-shadow:inset -10px -10px 15px var(--ion-box-shadow-color), inset 10px 10px 15px  rgba($color: #ffffff, $alpha: 0.5);
  }
  to{
    box-shadow: 10px 10px 15px var(--ion-box-shadow-color), -10px -10px 15px rgba($color: #ffffff, $alpha: 0.5);
  }
}

@keyframes button-press-animation{
  from{
    box-shadow: 10px 10px 15px var(--ion-box-shadow-color), -10px -10px 15px rgba($color: #ffffff, $alpha: 0.5);
  }
  to{
    box-shadow: none;
   // box-shadow: inset -10px -10px 15px var(--ion-box-shadow-color), inset 10px 10px 15px  rgba($color: #ffffff, $alpha: 0.5);
  }
}

.pulse {
  position: relative;
}

.pulse:before,
.pulse:after {
  content: "";
  display: block;
  position: absolute;
  box-shadow: var(--neomorphic-button-box-shadow);
  border-radius: 50%;
  z-index: -1;
  opacity: 0.7;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 7em;
  width: 7em;
  top: 50%;
  left: 50%;
}

.pulse:before {
  -webkit-animation: pulse 3s ease-out infinite; /* Increase the animation duration */
  animation: pulse 3s ease-out infinite;
}

.pulse:after {
  -webkit-animation: pulse 3s ease-out infinite; /* Increase the animation duration */
  animation: pulse 3s ease-out infinite;
  -webkit-animation-delay: 1.5s;
  animation-delay: 0.7s;
}

@-webkit-keyframes pulse {
  0% {
    opacity: 0.7;
  }
  70% {
    opacity: 0; /* Adjust the timing of opacity reaching 0 */
    height: 20em;
    width: 20em;
  }
  100% {
    opacity: 0; /* Ensure opacity remains at 0 until the end */
  }
}

@keyframes pulse {
  0% {
    opacity: 0.9;
  }
  70% {
    opacity: 0;
    height: 30em;
    width: 30em;
  }
  100% {
    opacity: 0;
  }
}



