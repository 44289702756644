
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// @use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
//@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
//$app-primary: mat.define-palette(mat.$indigo-palette);
//$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
//$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
// $app-theme: mat.define-light-theme((
//   color: (
//     primary: $app-primary,
//     accent: $app-accent,
//     warn: $app-warn,
//   )
// ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include mat.all-component-themes($app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
@font-face {   
  font-family: 'Roboto';    //This is what we are going to call
  src: url('../assets/Fonts/Roboto-Regular.ttf');
}

@font-face {   
  font-family: 'Comfortaa';    //This is what we are going to call
  src: url('../assets/Fonts/static/Comfortaa-Regular.ttf');
}

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #fe5f55;
  --ion-color-primary-rgb: 254, 95, 85;
  --ion-color-primary-contrast: #ececec;
  --ion-color-primary-contrast-rgb: 236,236,236;
  --ion-color-primary-shade: #e0544b;
  --ion-color-primary-tint: #fe6f66;

  --ion-color-secondary: #7a9e9f;
  --ion-color-secondary-rgb: 122, 158, 159;
  --ion-color-secondary-contrast: #ececec;
  --ion-color-secondary-contrast-rgb: 236,236,236;
  --ion-color-secondary-shade: #6b8b8c;
  --ion-color-secondary-tint: #87a8a9;

  --ion-color-tertiary: #a8bfbf;
  --ion-color-tertiary-rgb: 252,108,92;
  --ion-color-tertiary-contrast: #36373c;
  --ion-color-tertiary-contrast-rgb: 54,55,60;
  --ion-color-tertiary-shade: #a2bebe;
  --ion-color-tertiary-tint: #bfdcdc;

  --ion-color-success: #37907f;
  --ion-color-success-rgb: 55, 144, 127;
  --ion-color-success-contrast: #ececec;
  --ion-color-success-contrast-rgb: 236,236,236;
  --ion-color-success-shade: #307d6f;
  --ion-color-success-tint: #41a895;

  --ion-color-warning: #ab9b45;
  --ion-color-warning-rgb: 171, 155, 69;
  --ion-color-warning-contrast: #36373c;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #9a8c3d;
  --ion-color-warning-tint: #cfbd54;

  --ion-color-danger: #aa240e;
  --ion-color-danger-rgb: 170,36,14;
  --ion-color-danger-contrast: #ececec;
  --ion-color-danger-contrast-rgb: 236,236,236;
  --ion-color-danger-shade: #96200c;
  --ion-color-danger-tint: #b33a26;

  --ion-color-dark: #36373c;
  --ion-color-dark-rgb: 54,55,60;
  --ion-color-dark-contrast: #ececec;
  --ion-color-dark-contrast-rgb: 236,236,236;
  --ion-color-dark-shade: #303035;
  --ion-color-dark-tint: #4a4b50;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #ececec;
  --ion-color-medium-contrast-rgb: 236,236,236;
  --ion-color-medium-shade: #76777d;
  --ion-color-medium-tint: #b2b5bf;

  --ion-color-light: #ececec;
  --ion-color-light-rgb: 236,236,236;
  --ion-color-light-contrast: #36373c;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  


  // --ion-background-color	Background color of the entire app
  // --ion-background-color-rgb	Background color of the entire app, rgb format
  // --ion-text-color	Text color of the entire app
  // --ion-text-color-rgb	Text color of the entire app, rgb format
  // --ion-backdrop-color:#333333;
  // --ion-backdrop-opacity	Opacity of the Backdrop component
  // --ion-overlay-background-color	Background color of the overlays
  // --ion-border-color	Border color
   --ion-box-shadow-color:rgba(70,70,70,0.12);
  --ion-tab-bar-background:var(--ion-color-dark);	//Background of the Tab Bar
  // --ion-tab-bar-background-focused	Background of the focused Tab Bar
  // --ion-tab-bar-border-color	Border color of the Tab Bar
  // --ion-tab-bar-color	Color of the Tab Bar
  // --ion-tab-bar-color-selected	Color of the selected Tab Button
   --ion-toolbar-background:var(--ion-color-light);//Background of the Toolbar
  // --ion-toolbar-border-color	Border color of the Toolbar
  // --ion-toolbar-color	Color of the components in the Toolbar
   --ion-toolbar-segment-color:#7a9e9f;  //	Color of the Segment Buttons in the Toolbar
   --ion-toolbar-segment-color-checked:#ececec ; //	Color of the checked Segment Buttons in the Toolbar
   --ion-toolbar-segment-background:#ececec;	//Background of the Segment Buttons in the Toolbar
   --ion-toolbar-segment-background-checked:#7a9e9f;	//Background of the Segment Buttons in the Toolbar
   --ion-toolbar-segment-indicator-color:#ececec;	 //Color of the Segment Button indicator in the Toolbar
  // --ion-item-background	Background of the Item
  // --ion-item-border-color	Border color of the Item
  // --ion-item-color	Color of the components in the Item
  // --ion-placeholder-color	Color of the placeholder in Inputs

  --ion-background-color: #ececec;
  --ion-background-color-rgb: 235, 238, 241;

  --ion-text-color: #3d3e44;
  --ion-text-color-rgb: 61,62,68;

  --ion-color-step-50: #f5f5f6;
  --ion-color-step-100: #ececec;
  --ion-color-step-150: #e2e2e3;
  --ion-color-step-200: #d8d8da;
  --ion-color-step-250: #cfcfd0;
  --ion-color-step-300: #c5c5c7;
  --ion-color-step-350: #bbbbbe;
  --ion-color-step-400: #b1b2b4;
  --ion-color-step-450: #a8a8ab;
  --ion-color-step-500: #9e9fa2;
  --ion-color-step-550: #949598;
  --ion-color-step-600: #8b8b8f;
  --ion-color-step-650: #818285;
  --ion-color-step-700: #77787c;
  --ion-color-step-750: #6e6e73;
  --ion-color-step-800: #646569;
  --ion-color-step-850: #5a5b60;
  --ion-color-step-900: #505157;
  --ion-color-step-950: #47484d;

  --neomorphic-button-box-shadow:  10px 10px 15px var(--ion-box-shadow-color), -10px -10px 15px rgba(255, 255, 255, 0.5);

}

 @media (prefers-color-scheme: dark) {
  :root {
    --ion-color-primary: #fe5f55;
    --ion-color-primary-rgb: 254, 95, 85;
    --ion-color-primary-contrast: #ececec;
    --ion-color-primary-contrast-rgb: 236,236,236;
    --ion-color-primary-shade: #e0544b;
    --ion-color-primary-tint: #fe6f66;
  
    --ion-color-secondary: #7a9e9f;
    --ion-color-secondary-rgb: 122, 158, 159;
    --ion-color-secondary-contrast: #ececec;
    --ion-color-secondary-contrast-rgb: 236,236,236;
    --ion-color-secondary-shade: #6b8b8c;
    --ion-color-secondary-tint: #87a8a9;
  
    --ion-color-tertiary: #a8bfbf;
    --ion-color-tertiary-rgb: 252,108,92;
    --ion-color-tertiary-contrast: #36373c;
    --ion-color-tertiary-contrast-rgb: 54,55,60;
    --ion-color-tertiary-shade: #a2bebe;
    --ion-color-tertiary-tint: #bfdcdc;
  
    --ion-color-success: #37907f;
    --ion-color-success-rgb: 55, 144, 127;
    --ion-color-success-contrast: #ececec;
    --ion-color-success-contrast-rgb: 236,236,236;
    --ion-color-success-shade: #307d6f;
    --ion-color-success-tint: #41a895;
  
    --ion-color-warning: #ab9b45;
    --ion-color-warning-rgb: 171, 155, 69;
    --ion-color-warning-contrast: #36373c;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #9a8c3d;
    --ion-color-warning-tint: #cfbd54;
  
    --ion-color-danger: #aa240e;
    --ion-color-danger-rgb: 170,36,14;
    --ion-color-danger-contrast: #ececec;
    --ion-color-danger-contrast-rgb: 236,236,236;
    --ion-color-danger-shade: #96200c;
    --ion-color-danger-tint: #b33a26;
  
    --ion-color-dark: #36373c;
    --ion-color-dark-rgb: 54,55,60;
    --ion-color-dark-contrast: #ececec;
    --ion-color-dark-contrast-rgb: 236,236,236;
    --ion-color-dark-shade: #303035;
    --ion-color-dark-tint: #4a4b50;
  
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146,148,156;
    --ion-color-medium-contrast: #ececec;
    --ion-color-medium-contrast-rgb: 236,236,236;
    --ion-color-medium-shade: #76777d;
    --ion-color-medium-tint: #b2b5bf;
  
    --ion-color-light: #ececec;
    --ion-color-light-rgb: 236,236,236;
    --ion-color-light-contrast: #36373c;
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
    
  
  
    // --ion-background-color	Background color of the entire app
    // --ion-background-color-rgb	Background color of the entire app, rgb format
    // --ion-text-color	Text color of the entire app
    // --ion-text-color-rgb	Text color of the entire app, rgb format
    // --ion-backdrop-color:#333333;
    // --ion-backdrop-opacity	Opacity of the Backdrop component
    // --ion-overlay-background-color	Background color of the overlays
    // --ion-border-color	Border color
     --ion-box-shadow-color:rgba(70,70,70,0.12);
    --ion-tab-bar-background:var(--ion-color-dark);	//Background of the Tab Bar
    // --ion-tab-bar-background-focused	Background of the focused Tab Bar
    // --ion-tab-bar-border-color	Border color of the Tab Bar
    // --ion-tab-bar-color	Color of the Tab Bar
    // --ion-tab-bar-color-selected	Color of the selected Tab Button
     --ion-toolbar-background:var(--ion-color-light);//Background of the Toolbar
    // --ion-toolbar-border-color	Border color of the Toolbar
    // --ion-toolbar-color	Color of the components in the Toolbar
     --ion-toolbar-segment-color:#7a9e9f;  //	Color of the Segment Buttons in the Toolbar
     --ion-toolbar-segment-color-checked:#ececec ; //	Color of the checked Segment Buttons in the Toolbar
     --ion-toolbar-segment-background:#ececec;	//Background of the Segment Buttons in the Toolbar
     --ion-toolbar-segment-background-checked:#7a9e9f;	//Background of the Segment Buttons in the Toolbar
     --ion-toolbar-segment-indicator-color:#ececec;	 //Color of the Segment Button indicator in the Toolbar
    // --ion-item-background	Background of the Item
    // --ion-item-border-color	Border color of the Item
    // --ion-item-color	Color of the components in the Item
    // --ion-placeholder-color	Color of the placeholder in Inputs
  
    --ion-background-color: #ececec;
    --ion-background-color-rgb: 235, 238, 241;
  
    --ion-text-color: #3d3e44;
    --ion-text-color-rgb: 61,62,68;
  
    --ion-color-step-50: #f5f5f6;
    --ion-color-step-100: #ececec;
    --ion-color-step-150: #e2e2e3;
    --ion-color-step-200: #d8d8da;
    --ion-color-step-250: #cfcfd0;
    --ion-color-step-300: #c5c5c7;
    --ion-color-step-350: #bbbbbe;
    --ion-color-step-400: #b1b2b4;
    --ion-color-step-450: #a8a8ab;
    --ion-color-step-500: #9e9fa2;
    --ion-color-step-550: #949598;
    --ion-color-step-600: #8b8b8f;
    --ion-color-step-650: #818285;
    --ion-color-step-700: #77787c;
    --ion-color-step-750: #6e6e73;
    --ion-color-step-800: #646569;
    --ion-color-step-850: #5a5b60;
    --ion-color-step-900: #505157;
    --ion-color-step-950: #47484d;
  
    --neomorphic-button-box-shadow:  10px 10px 15px var(--ion-box-shadow-color), -10px -10px 15px rgba(255, 255, 255, 0.5);
  
  }
}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
